import React from "react";
import IconWrapper from "../IconWrapper";
import snippet from "./snippet.svg";
interface DashboardIconProps {}

const SnippetIcon: React.FunctionComponent<DashboardIconProps> = (props) => {
  return (
    <IconWrapper>
      <svg width="32" height="32" viewBox="0 0 32 32">
        <g
          transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            className="icon"
            d="M56 281 c-27 -30 -18 -52 32 -78 2 -1 -15 -29 -37 -62 -44 -64 -48
-78 -28 -103 9 -12 39 -17 131 -21 115 -5 119 -5 142 18 32 32 32 78 -1 111
-30 30 -30 32 -5 64 22 28 26 60 8 78 -8 8 -49 12 -118 12 -93 0 -109 -3 -124
-19z m156 -26 c-4 -24 -8 -25 -73 -25 -68 0 -86 8 -75 35 4 11 24 15 79 15 74
0 74 0 69 -25z m68 10 c17 -21 -22 -81 -65 -99 -41 -18 -59 -49 -52 -92 l6
-34 -65 0 c-85 0 -89 12 -30 100 l46 70 65 0 c65 0 75 6 53 28 -8 8 -8 15 2
27 16 19 24 19 40 0z m-50 -145 c0 -22 -4 -28 -15 -24 -21 9 -19 -10 5 -31 19
-17 21 -17 40 0 24 21 26 40 5 31 -11 -4 -15 2 -15 24 0 22 4 28 15 24 18 -7
35 -36 35 -58 0 -22 -36 -56 -60 -56 -26 0 -60 34 -60 60 0 18 32 60 45 60 3
0 5 -13 5 -30z"
          />
          <path
            className="icon"
            d="M120 170 c0 -5 16 -10 35 -10 19 0 35 5 35 10 0 6 -16 10 -35 10 -19
0 -35 -4 -35 -10z"
          />
          <path
            className="icon"
            d="M100 140 c0 -5 11 -10 25 -10 14 0 25 5 25 10 0 6 -11 10 -25 10 -14
0 -25 -4 -25 -10z"
          />
        </g>
      </svg>
      {/* <svg
        width="24"
        height="24"
        viewBox="0 0 74 74"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="icon"
          d="m12.19 47.44a1 1 0 0 1 -1-1v-2.97a1 1 0 0 1 2 0v2.97a1 1 0 0 1 -1 1z"
        />
        <path
          className="icon"
          d="m64.81 72h-52.62a1 1 0 0 1 -1-1v-17.56a1 1 0 0 1 2 0v16.56h50.62v-50.656l-15.344-15.344h-35.276v22.56a1 1 0 0 1 -2 0v-23.56a1 1 0 0 1 1-1h36.69a1 1 0 0 1 .707.293l15.93 15.93a1 1 0 0 1 .293.707v52.07a1 1 0 0 1 -1 1z"
        />
        <path
          className="icon"
          d="m12.19 37.47a1 1 0 0 1 -1-1v-2.91a1 1 0 0 1 2 0v2.91a1 1 0 0 1 -1 1z"
        />
        <path
          className="icon"
          d="m64.811 19.929h-15.929a1 1 0 0 1 -1-1v-15.929a1 1 0 0 1 2 0v14.929h14.929a1 1 0 0 1 0 2z"
        />
        <path
          className="icon"
          d="m56 51.5h-36a1 1 0 0 1 0-2h36a1 1 0 0 1 0 2z"
        />
        <path className="icon" d="m56 40h-36a1 1 0 0 1 0-2h36a1 1 0 0 1 0 2z" />
        <path className="icon" d="m56 63h-36a1 1 0 0 1 0-2h36a1 1 0 0 1 0 2z" />
        <path
          className="icon"
          d="m36.711 26.211a1 1 0 0 1 -.711-1.711l4-4-4-4a1 1 0 0 1 1.414-1.414l4.711 4.711a1 1 0 0 1 0 1.414l-4.711 4.711a1 1 0 0 1 -.703.289z"
        />
        <path
          className="icon"
          d="m24.652 26.211a1 1 0 0 1 -.707-.293l-4.711-4.711a1 1 0 0 1 0-1.414l4.711-4.711a1 1 0 0 1 1.414 1.418l-4 4 4 4a1 1 0 0 1 -.707 1.707z"
        />
        <path
          className="icon"
          d="m28.547 30a1.025 1.025 0 0 1 -.244-.03 1 1 0 0 1 -.726-1.214l4.27-17a1 1 0 1 1 1.939.488l-4.27 17a1 1 0 0 1 -.969.756z"
        />
      </svg> */}
    </IconWrapper>
  );
};

export default SnippetIcon;
