import * as React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

interface SectionProps {
  title: string;
}

const Section: React.FunctionComponent<SectionProps> = (props) => {
  return (
    <Wrapper>
      <h2>{props.title}</h2>
      {props.children}
    </Wrapper>
  );
};

export default Section;
