import React, { useState, useEffect } from "react";
import Table from "../Table";
import Button from "../Buttons/Button";
import { makeid, jsonFlatten } from "../../help";
import { List, Typography, Modal } from "antd";
// import { EditCreateUser } from "./EditCreateUser";
import { getToken } from "../../token/tokenUtil";
import { getProtalUsers, saveUsers } from "../../db/dbUtil";
import { EditCreateCustomRegex } from "./EditCreateCustomRegex";
import Checkbox from "../Checkbox";
interface UserItemProps {}

export const CustomRegexList = ({ customRegex, setData, jsonKey }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [createItemVisible, setCreateItemVisible] = useState(false);
  const [selectedRegex, setSelectedRegex] = useState(undefined);
  // const [customRegex, setCustomRegex] = React.useState(undefined);
  const [removedUsers, setRemovedUsers] = React.useState([]);
  const [usersBeforeSave, setUsersBeforeSave] = React.useState(undefined);
  // useEffect(() => {
  //   getToken().then(() => {
  //     getProtalUsers().then((data) => {
  //       customRegex(data as any);
  //       setUsersBeforeSave(JSON.parse(JSON.stringify(data as any)));
  //     });
  //   });
  // }, []);
  return (
    <div>
      {customRegex && customRegex.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th />
              <th />
              <th>Name</th>
              <th>Format</th>
              <th>Ignore low/upp case</th>
              <th>Contain</th>
            </tr>
          </thead>
          <tbody>
            {customRegex.map((regex) => (
              <tr key={makeid()}>
                <td style={{ width: 100 }}>
                  <Button
                    secondary
                    small
                    onClick={(event) => {
                      Modal.confirm({
                        title:
                          "Are you sure that you want to remove regex " +
                          regex.name +
                          "?",
                        okText: "Yes",
                        cancelText: "No",
                        onOk: () => {
                          setData((prevState) => {
                            const copy = { ...prevState };
                            copy[jsonKey] = prevState[jsonKey].filter(
                              (x) => x.name !== regex.name
                            );
                            return copy;
                          });
                        },
                      });
                    }}
                  >
                    X
                  </Button>
                </td>
                <td style={{ width: 100 }}>
                  <Button
                    secondary
                    small
                    onClick={() => {
                      setCreateItemVisible(true);
                      setIsEdit(true);
                      setSelectedRegex(regex);
                    }}
                  >
                    Edit
                  </Button>
                </td>
                <td
                  style={{
                    maxWidth: "20em",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {regex.name}
                </td>
                <td
                  style={{
                    maxWidth: "20em",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {regex.format}
                </td>
                <td>
                  <Checkbox
                    label=""
                    value={regex.ignore}
                    handleChange={(e) => {}}
                  />
                </td>
                <td>
                  <Checkbox
                    label=""
                    value={regex.single}
                    handleChange={(e) => {}}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No custom regex have been added yet.</p>
      )}
      <Button
        onClick={() => {
          setCreateItemVisible(true);
          setIsEdit(false);
        }}
      >
        {"Add Custom Text"}
      </Button>
      {isEdit === true && (
        <EditCreateCustomRegex
          _visible={createItemVisible}
          setVisible={setCreateItemVisible}
          title={"Edit Custom Regex"}
          jsonKey={jsonKey}
          regex={selectedRegex}
          setData={setData}
        />
      )}
      {isEdit === false && (
        <EditCreateCustomRegex
          _visible={createItemVisible}
          setVisible={setCreateItemVisible}
          title={"Add Custom Regex"}
          regex={undefined}
          jsonKey={jsonKey}
          setData={setData}
        />
      )}
    </div>
  );
};
