import * as React from 'react'
import styled from '@emotion/styled'
import logoFull from './qp-logo-full.png'

const Wrapper = styled.div`
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
`

const Logo = styled.img`
  width: 68px;
  height: 16px;
`

interface SidebarBottomLogoProps {}

const SidebarBottomLogo: React.FunctionComponent<SidebarBottomLogoProps> = props => {
  return (
    <Wrapper>
      <Logo src={logoFull} />
    </Wrapper>
  )
}

export default SidebarBottomLogo
