import * as React from "react";

interface ErrorProps {
  message: string;
  error?: any;
}

const Error: React.FunctionComponent<ErrorProps> = (props) => {
  if (props.error) console.error(props.error);

  return <div style={{ color: "red" }}>{props.message || "Error..."}</div>;
};

export default Error;
