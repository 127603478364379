import styled from "@emotion/styled";

const IconWrapper = styled.div`
  .icon {
    fill: var(--bluey-grey);
  }

  .menu-item-active & .icon {
    fill: var(--vibrant-blue);
  }
`;

export default IconWrapper;
