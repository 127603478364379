import React from "react";
import { Dropdown, Button, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
const DropDown = ({ dtitle, menu }) => {
  return (
    <Dropdown overlay={menu}>
      <Button>
        {dtitle}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};
export default DropDown;
