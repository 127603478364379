import React, { useState } from "react";
import Table from "../Table";
import Button from "../Buttons/Button";
import { makeid } from "../../help";
import { EditCreateItem } from "./EditCreateItem";
import { Modal } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
interface ItemListProps {
  jsonKey: string;
  title: string;
  itemList?: string[];
  remove: (key: string, name: string) => void;
  add: (key: string, name: string) => void;
  edit: (key: string, oldName: string, newName: string) => void;
  validation: (key: string, value: string) => void;

  forwardToKey?: string;
}

export const ItemList: React.FunctionComponent<ItemListProps> = ({
  jsonKey,
  title,
  itemList,
  remove,
  add,
  edit,
  validation,
  forwardToKey,
}) => {
  const [createItemVisible, setCreateItemVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selected, setSelected] = useState("");
  //   if (loading) return <Loading />;
  return (
    <div>
      {itemList && itemList.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th />
              <th />
              {forwardToKey && <th />}
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {itemList.map((domain) => (
              <tr key={makeid()}>
                <td style={{ width: 100 }}>
                  <Button
                    secondary
                    small
                    onClick={() => {
                      Modal.confirm({
                        title:
                          "Are you sure that you want to remove " +
                          domain +
                          "?",
                        okText: "Yes",
                        cancelText: "No",
                        onOk: () => {
                          remove(jsonKey, domain);
                        },
                      });
                    }}
                  >
                    X
                  </Button>
                </td>
                <td style={{ width: 100 }}>
                  <Button
                    secondary
                    small
                    onClick={() => {
                      setCreateItemVisible(true);
                      setIsEdit(true);
                      setSelected(domain);
                    }}
                  >
                    Edit
                  </Button>
                </td>
                {forwardToKey && (
                  <td style={{ width: 100, whiteSpace: "nowrap" }}>
                    <Button
                      secondary
                      small
                      onClick={() => {
                        add(forwardToKey, domain);
                        remove(jsonKey, domain);
                      }}
                    >
                      {forwardToKey === "BCookies"
                        ? "Blocked Cookies"
                        : forwardToKey === "ACookies"
                        ? "Allowed Cookies"
                        : forwardToKey}
                    </Button>
                  </td>
                )}
                <td
                  style={{
                    maxWidth: "20em",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {domain}{" "}
                  {jsonKey.toLowerCase().indexOf("cookie") > -1 && (
                    <a
                      target="_blank"
                      href={"https://cookiepedia.co.uk/cookies/" + domain}
                    >
                      <InfoCircleOutlined />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>{`No ${title.toLowerCase()} have been added yet.`}</p>
      )}
      <Button
        onClick={() => {
          setCreateItemVisible(true);
          setIsEdit(false);
        }}
      >
        {"Add " +
          (title.endsWith("s")
            ? title.toLowerCase().substring(0, title.length - 1)
            : title.toLowerCase())}
      </Button>
      {isEdit === false && (
        <EditCreateItem
          _visible={createItemVisible}
          setVisible={setCreateItemVisible}
          title={
            "Add " +
            (title.endsWith("s")
              ? title.toLowerCase().substring(0, title.length - 1)
              : title.toLowerCase())
          }
          onCreateClick={add}
          jsonKey={jsonKey}
          oldValue={undefined}
          validation={validation}
        />
      )}
      {isEdit === true && (
        <EditCreateItem
          _visible={createItemVisible}
          setVisible={setCreateItemVisible}
          title={
            "Edit " +
            (title.endsWith("s")
              ? title.toLowerCase().substring(0, title.length - 1)
              : title.toLowerCase()) +
            " - " +
            selected
          }
          onCreateClick={edit}
          jsonKey={jsonKey}
          oldValue={selected}
          validation={validation}
        />
      )}
    </div>
  );
};
