import styled from "@emotion/styled";

const Table = styled.table`
  width: 100%;
  text-align: left;
  margin-left: 20px;
  thead {
    color: var(--light-navy);
  }

  th,
  td {
    padding: 16px 16px;
    border-bottom: 1px solid #e8e8e8;
  }

  margin-bottom: 20px;
`;
// style={{ textAlign: "left", marginLeft: "20px;" }}
export default Table;
