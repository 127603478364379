import React, { useState, useEffect } from "react";
import Table from "../Table";
import Button from "../Buttons/Button";
import { makeid } from "../../help";
import DropDown from "../DropDown";
import { Menu, Modal } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import regexConfig from "../../config/regexes.json";
import { DropDownAutoComplete } from "../DropDownAutoComplete";

interface RegexListProps {
  regexList: { rID: string; gID: string }[];
  jsonKey: string;
  // remove: (regGroupID: string, regexID: string) => void;
  error?: string;
  setData: Function;
}

export const RegexList: React.FunctionComponent<RegexListProps> = ({
  regexList,
  jsonKey,
  // remove,
  error,
  setData,
}) => {
  //get only the regex which selected by user.
  const [filterRegex, setFilterRegex] = React.useState([]);
  const [allRegexGroup, setAllRegexGroup] = React.useState([]);
  const [selecteRegexdGroup, setSelecteRegexdGroup] = React.useState(undefined);
  const [selecteRegexd, setSelecteRegex] = React.useState(undefined);
  const [isShowAddRegex, setIsShowAddRegex] = React.useState(false);
  const [allRegexByGroup, setAllRegexByGroup] = React.useState([]);
  useEffect(() => {
    setAllRegexGroup([]);
    regexConfig.forEach((regJson) => {
      setAllRegexGroup((prevState) => {
        let copy = [...prevState];
        copy.push({
          value: regJson.name,
          full: regJson,
        });
        return copy;
      });
    });
  }, []);
  useEffect(() => {
    setAllRegexByGroup([]);
    if (selecteRegexdGroup) {
      selecteRegexdGroup.regex.forEach((regJson) => {
        if (
          !filterRegex.find(
            (x) => x.g_key === selecteRegexdGroup.key && x.r_key === regJson.key
          )
        ) {
          setAllRegexByGroup((prevState) => {
            let copy = [...prevState];
            copy.push({
              value: regJson.name,
              full: regJson,
            });
            return copy;
          });
        }
      });
    }
  }, [selecteRegexdGroup, filterRegex]);

  useEffect(() => {
    regexList &&
      regexList.map((userSelectedRegexKeys) => {
        const regexGroup = regexConfig.find(
          (x) => x.key === userSelectedRegexKeys.gID
        );
        if (regexGroup) {
          const r = regexGroup.regex.find(
            (x) => x.key === userSelectedRegexKeys.rID
          );
          if (r) {
            const newRegexJson = {
              g_key: regexGroup.key,
              g_name: regexGroup.name,
              r_key: r.key,
              r_name: r.name,
              r_value: r.value,
            };
            setFilterRegex((prevState) => {
              let copy = [...prevState];
              copy.push(newRegexJson);
              return copy;
            });
          }
        }
      });
  }, []);

  //   if (loading) return <Loading />;
  if (error) {
    // return <Error message={"Error loading data"} error={error} />;
  }

  return (
    <div>
      {filterRegex && filterRegex.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th />
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {filterRegex.map((regexIDs) => (
              <tr key={makeid()}>
                <td style={{ width: 100 }}>
                  <Button
                    secondary
                    small
                    onClick={() => {
                      Modal.confirm({
                        title:
                          "Are you sure that you want to remove regex " +
                          regexIDs.r_name +
                          "?",
                        okText: "Yes",
                        cancelText: "No",
                        onOk: () => {
                          setFilterRegex((prevState) => {
                            return prevState.filter(
                              (x) =>
                                !(
                                  x.g_key === regexIDs.g_key &&
                                  x.r_key === regexIDs.r_key
                                )
                            );
                          });
                        },
                      });
                    }}
                  >
                    X
                  </Button>
                </td>
                <td>{regexIDs.g_name + "-->" + regexIDs.r_name}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>{`No regex have been added yet.`}</p>
      )}
      <DropDownAutoComplete
        options={allRegexGroup}
        placeholder="Select group"
        onselect={(value, option) => {
          setSelecteRegexdGroup(option.full);
        }}
        onchange={() => {
          setSelecteRegexdGroup(undefined);
        }}
      />
      <DropDownAutoComplete
        options={allRegexByGroup}
        placeholder="Select content control"
        onchange={() => {
          setSelecteRegex(undefined);
          setIsShowAddRegex(false);
        }}
        onselect={(value, option) => {
          setSelecteRegex(option);
          setIsShowAddRegex(true);
        }}
      />
      {isShowAddRegex && (
        <Button
          style={{ verticalAlign: "bottom" }}
          onClick={() => {
            setData((prevState) => {
              let copy = { ...prevState };
              if (!copy) copy = {};
              if (!copy[jsonKey]) copy[jsonKey] = [];

              copy[jsonKey].push({
                rID: selecteRegexd.full.key,
                gID: selecteRegexdGroup.key,
              });
              return copy;
            });
            setFilterRegex((prevState) => {
              const regexGroup = selecteRegexdGroup;
              if (regexGroup) {
                const r = selecteRegexd.full;
                if (r) {
                  let copy = [...prevState];
                  if (!copy) copy = [];
                  copy.push({
                    g_key: regexGroup.key,
                    g_name: regexGroup.name,
                    r_key: r.key,
                    r_name: r.name,
                    r_value: r.value,
                  });
                  return copy;
                }
              }
              return [];
            });
            setIsShowAddRegex(false);
          }}
        >
          {"Add Content"}
        </Button>
      )}
    </div>
  );
};
