import * as React from "react";
import styled from "@emotion/styled";
import { NavLink, Link } from "react-router-dom";
import SideBarBackground from "./sidebar-background.svg";
import SidebarLogo from "../../components/SidebarLogo";
import NotificationsIcon from "../../components/svg/NotificationsIcon";
import ConfigIcon from "../../components/svg/ConfigIcon";
import SnippetIcon from "../../components/svg/SnippetIcon";
import AlertsIcon from "../../components/svg/AlertsIcon";
import { RiLogoutCircleLine } from "react-icons/ri";
import LogoutIcons from "../../components/svg/LogoutIcons";
import Button from "../../components/Buttons/Button";
import { Modal, Tooltip } from "antd";
import { removeToken, logoutRedirect } from "../../token/tokenUtil";
import SidebarBottomLogo from "./SidebarBottomLogo";
import CopyrightIcon from "../../components/svg/CopyrightIcon";

const MIN_HEIGHT = 767;

const SidebarWrapper = styled.div`
  width: 130px;
  height: 100vh;
  z-index: 10;
  min-height: ${MIN_HEIGHT}px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ColorBar = styled.div<{ risk: number }>`
  width: 20px;
  height: 400px;
  position: fixed;
  left: 73px;
  margin-right: 37px;
  background-image: radial-gradient(
    circle at 128% 50%,
    var(--aqua-marine),
    var(--aqua-marine-35)
  );

  transition: background-image 0.6s ease;
`;

const SidebarBackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 130px;
  min-height: ${MIN_HEIGHT}px;
  background-image: url(${SideBarBackground});
  background-position: center;
  background-position-x: -10px;
`;

const SidebarMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
`;

const SidebarMenuWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  min-height: ${MIN_HEIGHT}px;
`;

const SidebarMenu = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const SidebarMenuItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 15px;
  padding-bottom: 8px;
  padding-left: 4px;
  margin-top: 5px;
  margin-bottom: 5px;

  border-right-width: 4px;
  border-right-style: solid;
  border-right-color: transparent;

  :hover {
    .icon {
      fill: var(--dark-indigo);
    }
  }

  .menu-item-active & {
    :hover {
      .icon {
        fill: var(--vibrant-blue);
      }
    }

    background-color: var(--vibrant-blue-5);
    border-right-color: var(--vibrant-blue);
  }
`;

interface SidebarProps {
  // risk: number;
}
const tooltipColor = "var(--vibrant-blue)";
const tooltipmouseEnterDelay = 1;
const Sidebar: React.FunctionComponent<SidebarProps> = (props) => {
  // const { risk } = props;
  //   const {
  //     userState: { user },
  //   } = useUserContext();

  return (
    <SidebarWrapper>
      <ColorBar risk={0} />
      <SidebarBackgroundWrapper>
        <SidebarMenuWrapper>
          <SidebarMenuWrapperInner>
            <div>
              <Link to="/">
                <SidebarLogo />
              </Link>
              <SidebarMenu>
                <NavLink to="/" activeClassName="menu-item-active" exact>
                  <Tooltip
                    placement="right"
                    title="Config"
                    color={tooltipColor}
                    mouseEnterDelay={tooltipmouseEnterDelay}
                  >
                    <SidebarMenuItem>{<ConfigIcon />}</SidebarMenuItem>{" "}
                  </Tooltip>
                </NavLink>
                <NavLink to="/notifications" activeClassName="menu-item-active">
                  <Tooltip
                    placement="right"
                    title="Notifications"
                    color={tooltipColor}
                    mouseEnterDelay={tooltipmouseEnterDelay}
                  >
                    <SidebarMenuItem>{<NotificationsIcon />}</SidebarMenuItem>
                  </Tooltip>
                </NavLink>
                <NavLink to="/alerts" activeClassName="menu-item-active">
                  <Tooltip
                    placement="right"
                    title="Alerts"
                    color={tooltipColor}
                    mouseEnterDelay={tooltipmouseEnterDelay}
                  >
                    <SidebarMenuItem>{<AlertsIcon />}</SidebarMenuItem>
                  </Tooltip>
                </NavLink>
                <NavLink to="/sdk" activeClassName="menu-item-active">
                  <Tooltip
                    placement="right"
                    title="SDK Snippet"
                    color={tooltipColor}
                    mouseEnterDelay={tooltipmouseEnterDelay}
                  >
                    <SidebarMenuItem>{<SnippetIcon />}</SidebarMenuItem>
                  </Tooltip>
                </NavLink>
              </SidebarMenu>
            </div>

            <SidebarMenu>
              <NavLink to="/copyright" activeClassName="menu-item-active">
                <Tooltip
                  placement="right"
                  title="Copyright"
                  color={tooltipColor}
                  mouseEnterDelay={tooltipmouseEnterDelay}
                >
                  <SidebarMenuItem>{<CopyrightIcon />}</SidebarMenuItem>{" "}
                </Tooltip>
              </NavLink>
              <Tooltip
                placement="right"
                title="Logout"
                color={tooltipColor}
                mouseEnterDelay={tooltipmouseEnterDelay}
              >
                <Button
                  secondary
                  small
                  style={{ width: "100%", height: "50px", border: "none" }}
                  onClick={() => {
                    Modal.confirm({
                      title: "Are you sure that you want to logout?",
                      okText: "Yes",
                      cancelText: "No",
                      onOk: () => {
                        removeToken();
                        logoutRedirect();
                      },
                    });
                  }}
                >
                  <LogoutIcons />
                </Button>
              </Tooltip>
              <SidebarBottomLogo />
            </SidebarMenu>
          </SidebarMenuWrapperInner>
        </SidebarMenuWrapper>
      </SidebarBackgroundWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
