import React, { useEffect } from "react";
import Section from "../components/Section";
import { makeid } from "../help";
import { getToken } from "../token/tokenUtil";
import { getAlerts, saveRemoeAlerts } from "../db/dbUtil";
import Table from "../components/Table";
import Button from "../components/Buttons/Button";
import { Modal } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import DatePicker from "antd/lib/date-picker";
import { AlertStatus } from "../statics";
import Label from "../components/Label";
export const Alerts = () => {
  const [fromDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();
  const [alerts, setAlerts] = React.useState(undefined);
  const [removedAlerts, setRemovedAlerts] = React.useState([]);
  useEffect(() => {
    getToken().then(() => {
      getAlerts(AlertStatus.open).then((data) => {
        // console.log("setData");
        setAlerts(data as any);
        //setDataCompare(JSON.parse(JSON.stringify(data as any)));
      });
    });
  }, []);

  return (
    <Section title="Alerts">
      <div className="App" style={{ display: "inline-grid" }}>
        <div>
          <Label htmlFor="date" style={{ margin: "0px 5px 0px 20px" }}>
            From
          </Label>
          <DatePicker
            showTime
            placeholder="From"
            onChange={(value) => setFromDate(value as any)}
          />
          <Label htmlFor="date" style={{ margin: "0px 5px 0px 20px" }}>
            To
          </Label>
          <DatePicker
            showTime
            placeholder="To"
            onChange={(value) => setToDate(value as any)}
          />
          <Button
            style={{ width: "55px", float: "left" }}
            secondary
            small
            onClick={() => {
              getToken().then(() => {
                getAlerts(
                  fromDate || toDate ? AlertStatus.all : AlertStatus.open,
                  (fromDate as any)?.valueOf(),
                  (toDate as any)?.valueOf()
                ).then((data) => {
                  // console.log("setData");
                  setAlerts(data as any);
                  //setDataCompare(JSON.parse(JSON.stringify(data as any)));
                });
              });
            }}
          >
            <ReloadOutlined />
          </Button>
        </div>
        {alerts && alerts.length > 0 && (
          <div style={{ maxHeight: "800px", overflowY: "auto" }}>
            <Table style={{ margin: "auto" }}>
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {alerts.map((alert) => (
                  <tr key={makeid()}>
                    <td style={{ width: 100 }}>
                      <Button
                        secondary
                        small
                        onClick={() => {
                          Modal.confirm({
                            title:
                              "Are you sure that you want to resolve alert" +
                              `${alert.alertType}-${alert.info} (${alert.alertcount} User affected)` +
                              "?",
                            okText: "Yes",
                            cancelText: "No",
                            onOk: () => {
                              setAlerts((prevState) => {
                                return prevState.filter(
                                  (x) =>
                                    !(
                                      x.alertType === alert.alertType &&
                                      x.info === alert.info
                                    )
                                );
                              });
                              setRemovedAlerts((prevState) => {
                                return [...prevState, alert];
                              });
                            },
                          });
                        }}
                      >
                        Resolve
                      </Button>
                    </td>
                    <td>{`${alert.alertType}-${alert.info} (${alert.alertcount} User affected)`}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {removedAlerts && removedAlerts.length > 0 && (
          <Button
            onClick={async () => {
              setRemovedAlerts([]);
              await saveRemoeAlerts(removedAlerts);
            }}
          >
            Save
          </Button>
        )}
      </div>
    </Section>
  );
};
