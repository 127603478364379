import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Modal, Input } from "antd";
import Checkbox from "../Checkbox";
import Error from "../Error";
export const EditCreateCustomRegex = ({
  _visible,
  setVisible,
  title,
  jsonKey,
  regex,
  setData,
}) => {
  const [regexBeforeChange, setRegexBeforeChange] = useState(undefined);
  const [name, setName] = useState("");
  const [format, setFormat] = useState("");
  const [ignore, setIgnore] = useState(false);
  const [single, setSingle] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {
    setError("");
    if (regex) {
      setRegexBeforeChange(JSON.parse(JSON.stringify(regex)));
      setName(regex.name);
      setFormat(regex.format);
      setIgnore(regex.ignore);
      setSingle(regex.single);
    }
  }, [regex]);
  const handleOk = () => {
    if (!name && !format) {
      setError("Name and Format are required");
      return;
    } else if (!format) {
      setError("Format is required");
      return;
    } else if (!name) {
      setError("Name is required");
      return;
    }
    setData((prevState) => {
      let copy = { ...prevState };
      if (!copy) copy = {};
      if (!copy[jsonKey]) copy[jsonKey] = [];
      const i = copy[jsonKey].findIndex((x) => x.name === name);
      const reg = {
        format: format,
        ignore: ignore,
        name: name,
        single: single,
      };
      if (i === -1) copy[jsonKey].push(reg);
      else copy[jsonKey][i] = reg;
      return copy;
    });
    setError("");
    setVisible(false);
  };

  const handleCancel = () => {
    setError("");
    setVisible(false);
  };

  return (
    <Modal
      title={title}
      visible={_visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Insert regex name"
      />
      <Input
        value={format}
        onChange={(e) => setFormat(e.target.value)}
        placeholder="Insert regex format"
      />
      <Checkbox
        label="Ignore low/app case"
        value={ignore}
        handleChange={(e) => setIgnore(e.target.checked)}
      />
      <Checkbox
        label="Contain"
        value={single}
        handleChange={(e) => setSingle(e.target.checked)}
      />
      {error && <Error message={error} />}
    </Modal>
  );
};
