import { config as loadConfigFiles } from "dotenv";
loadConfigFiles();
export enum AlertPlatform {
  email = "Email",
  webhook = "Webhook",
}
export enum AlertStatus {
  open = "open",
  resolve = "resolve",
  all = "all",
}

export const Header_Code = "x-code";
export const Header_Token = "x-token";
export const writeLogsDomain = process.env.REACT_APP_WRITE_LOG_DOMAIN
  ? process.env.REACT_APP_WRITE_LOG_DOMAIN
  : "https://wdy3mwizq2.execute-api.eu-west-2.amazonaws.com/dev";
// export const serverDomain = "http://localhost:4001";
export const serverDomain = process.env.REACT_APP_SERVER_DOMAIN
  ? process.env.REACT_APP_SERVER_DOMAIN
  : "https://bn85wwxap0.execute-api.eu-west-2.amazonaws.com/dev";
export const Client_Id = process.env.REACT_APP_CLIENT_ID
  ? process.env.REACT_APP_CLIENT_ID
  : "6qva1536mgt2m55a7augc3avic";
export const Pool_Region = process.env.REACT_APP_POOL_REGION
  ? process.env.REACT_APP_POOL_REGION
  : "eu-west-2";
export const Cognito_Domain = process.env.REACT_APP_COGNITO_DOMAIN
  ? process.env.REACT_APP_COGNITO_DOMAIN
  : "marketplace-portal";

export const hardCodedCookie = [
  "Expires",
  "Max-Age",
  "Domain",
  "Path",
  "Secure",
  "HttpOnly",
  "SameSite",
];
