import { AutoComplete, Input } from "antd";
import React from "react";

const options = [
  { value: "Burns Bay Road" },
  { value: "Downing Street" },
  { value: "Wall Street" },
];

export const DropDownAutoComplete = ({
  options,
  onselect,
  onchange,
  placeholder,
}) => (
  <AutoComplete
    dropdownMatchSelectWidth={250}
    style={{ width: 250, marginRight: "20px" }}
    options={options}
    filterOption={(inputValue, option) =>
      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }
    onSelect={onselect}
    onChange={onchange}
  >
    <Input.Search size="large" placeholder={placeholder} />
  </AutoComplete>
);
