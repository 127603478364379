import React, { useEffect } from "react";
import regexConfig from "../config/regexes.json";
// import all the styles
import "code-highlight/lib/style.css";
import "highlight.js/styles/xcode.css";
import { writeLogsDomain, hardCodedCookie } from "../statics";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import Section from "../components/Section";
export const InfoGuide = ({ data }) => {
  const [code, setCode] = React.useState("");
  useEffect(() => {
    console.log("set code");
    if (data) {
      let regexList = [];
      data["Regex"] &&
        data["Regex"].map((regJson) => {
          const regexGroup = regexConfig.find((x) => x.key === regJson.gID);
          if (regexGroup) {
            const r = regexGroup.regex.find((x) => x.key === regJson.rID);
            if (r) {
              regexList.push([r.value, ""]);
            }
          }
        });
      data["CustomRegex"] &&
        data["CustomRegex"].map((regJson) => {
          regexList.push(
            simple2regex(regJson.format, regJson.ignore, regJson.single)
          );
        });

      let trusted = data["Trusted"] ? JSON.stringify(data["Trusted"]) : [];
      (trusted as any) = Array.isArray(trusted) ? trusted : [trusted];
      let allowed = data["Allowed"] ? JSON.stringify(data["Allowed"]) : [];
      (allowed as any) = Array.isArray(allowed) ? allowed : [allowed];
      let blocked = data["Blocked"] ? JSON.stringify(data["Blocked"]) : [];
      (blocked as any) = Array.isArray(blocked) ? blocked : [blocked];
      let cookiesList = [];
      let aCokie = data["ACookies"] ? (data["ACookies"] as any) : [];
      (aCokie as any) = Array.isArray(aCokie) ? aCokie : [aCokie];
      let bCokie = data["BCookies"] ? (data["BCookies"] as any) : [];
      (bCokie as any) = Array.isArray(bCokie) ? bCokie : [bCokie];
      cookiesList = cookiesList.concat(hardCodedCookie).concat(aCokie);
      //   .concat(bCokie);
      setCode(
        `<!DOCTYPE html>
      <html>
          <head>
            <script>window.QPServer="${writeLogsDomain}/${
          data.bid ? data.bid : ""
        }";
              window.QPSegment="QPLite";
              window.QPTrustedDomains=${trusted};
              window.QPLiteGrayList=${allowed};
              window.QPLiteBlackList=${blocked};
              window.QPLiteRegexList=${JSON.stringify(regexList)};
              window.QPLiteGrayCookie=${JSON.stringify(cookiesList)};
              window.QPLiteBLackCookie=${JSON.stringify(bCokie)};
              window.QPLiteSdk=true;
              window.QPWorkerPath="${document.location.origin}/worker.js"
            </script>
            <script src="${document.location.origin}/websdk2.js"></script>
          </head>
      <body>
          <a href="https://www.google.com">gray list example</a>
          <br>
          <a href="https://www.example.com">black list example</a>
      </body>
      </html>`
      );
    }
  }, [data]);

  const options = {
    // selectOnLineNumbers: true,
  };
  return (
    <Section title="SDK Snippet">
      <div style={{ width: "70%", position: "absolute" }}>
        {code && (
          <SyntaxHighlighter language="javascript" style={docco}>
            {code}
          </SyntaxHighlighter>
        )}
      </div>
    </Section>
  );
};

function simple2regex(simple, ignore, contain): any[] {
  let allSimpleChars = simple.split(/\{(?:.-.)(?:,(?:.,.))?\}/g);
  allSimpleChars = allSimpleChars.map((e) => {
    let chars = e.replace(/([\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$1"); // Disable effect
    return chars.replace(/\\\*/g, ".*").replace(/\\\?/g, ".");
  });

  let allRangers = [...simple.matchAll(/\{(.-.)(?:,(.,.))?\}/g)];
  allRangers = allRangers.map((e) => {
    if (e[2]) {
      return `[${e[1]}]{${e[2]}}`;
    } else {
      return `[${e[1]}]`;
    }
  });
  //console.log(allSimpleChars)
  //console.log(allRangers)
  if (allSimpleChars.length == 0) return ["", ""];
  if (allSimpleChars.length == 1) return [allSimpleChars[0], ""];
  if (allSimpleChars.length - 1 != allRangers.length)
    throw new Error("Why splitter length is not N-1?");
  let result = "";
  for (let i = 0; i < allSimpleChars.length - 1; i++) {
    result += allSimpleChars[i] + allRangers[i];
  }
  result += allSimpleChars[allSimpleChars.length - 1];
  if (contain === false) {
    result = "\\b" + result + "\\b";
  }
  return [result, ignore === true ? "i" : ""];
}
