import React from "react";
import IconWrapper from "../IconWrapper";

interface DashboardIconProps {}

const CopyrightIcon: React.FunctionComponent<DashboardIconProps> = (props) => {
  return (
    <IconWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 512 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="icon"
          d="m256 512c-68.378906 0-132.667969-26.628906-181.019531-74.980469-48.351563-48.351562-74.980469-112.640625-74.980469-181.019531s26.628906-132.667969 74.980469-181.019531c48.351562-48.351563 112.640625-74.980469 181.019531-74.980469s132.667969 26.628906 181.019531 74.980469c48.351563 48.351562 74.980469 112.640625 74.980469 181.019531s-26.628906 132.667969-74.980469 181.019531c-48.351562 48.351563-112.640625 74.980469-181.019531 74.980469zm0-472c-119.101562 0-216 96.898438-216 216s96.898438 216 216 216 216-96.898438 216-216-96.898438-216-216-216zm105.359375 321.359375-28.285156-28.285156c-42.5 42.5-111.648438 42.5-154.148438 0s-42.5-111.648438 0-154.148438 111.648438-42.5 154.148438 0l28.285156-28.285156c-58.097656-58.09375-152.625-58.09375-210.71875 0-58.09375 58.097656-58.09375 152.625 0 210.71875 29.046875 29.046875 67.203125 43.570313 105.359375 43.570313 38.152344 0 76.3125-14.523438 105.359375-43.570313zm0 0"
        />
      </svg>
    </IconWrapper>
  );
};

export default CopyrightIcon;
