import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface ButtonProps {
  full?: boolean;
  secondary?: boolean;
  plain?: boolean;
  margin?: boolean;
  small?: boolean;
  isLabel?: boolean;
}

const Button = styled.button<ButtonProps>`
  background-color: var(--vibrant-blue);
  border: 2px solid var(--vibrant-blue);
  box-shadow: 0 8px 12px 0 rgba(24, 56, 241, 0.2);
  padding: ${(props) => (props.small ? "8px 20px" : "13px 30px")};
  cursor: pointer;

  color: #ffffff;
  font-weight: bold;
  line-height: 18px;
  text-align: center;

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${(props) =>
    props.full &&
    css`
      width: 100%;
    `}

  :hover {
    background-color: var(--light-vibrant-blue);
    border: 2px solid var(--light-vibrant-blue);
  }

  :disabled {
    background-color: var(--light-periwinkle);
    border: 2px solid var(--light-periwinkle);
    box-shadow: 0 8px 12px 0 rgba(221, 223, 236, 0.2);
  }

  ${(props) =>
    props.secondary &&
    css`
      border: 2px solid var(--vibrant-blue);
      background-color: var(--white);
      color: var(--vibrant-blue);
      box-shadow: none;

      :hover {
        background-color: rgba(24, 56, 241, 0.1);
      }
    `}

  ${(props) =>
    props.plain &&
    css`
      border: 2px solid transparent;
      background-color: transparent;
      color: var(--vibrant-blue);
      box-shadow: none;

      :hover {
        background-color: rgba(24, 56, 241, 0.1);
        border: 2px solid rgba(24, 56, 241, 0.1);
        /* background-color: transparent; */
        /* border: 2px solid transparent; */
      }
    `}
  
  ${(props) =>
    props.isLabel &&
    css`
      border: 2px solid transparent;
      background-color: transparent;
      color: var(--bluey-grey);
      box-shadow: none;

      :hover {
        background-color: transparent;
        border: 2px solid transparent;
      }
    `}

    ${(props) =>
    props.margin &&
    css`
      margin-right: 8px;
      margin-bottom: 12px;
    `}
`;

export default Button;
