import React from "react";
import IconWrapper from "../IconWrapper";

interface DashboardIconProps {}

const NotificationsIcon: React.FunctionComponent<DashboardIconProps> = (
  props
) => {
  return (
    <IconWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 26 26  "
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="icon"
          d="M26,21.5L25,20c-1.6-2.4-2.4-5.3-2.4-8.2c0-3.1-2-5.7-4.7-6.5C17.6,4.5,16.8,4,16,4s-1.6,0.5-1.9,1.3
          c-2.7,0.8-4.7,3.4-4.7,6.5c0,2.9-0.8,5.8-2.4,8.2l-1,1.5c-0.4,0.7,0,1.5,0.8,1.5h18.3C25.9,23,26.4,22.1,26,21.5z"
        />
        <path className="icon" d="M19,26c0,1.7-1.3,3-3,3s-3-1.3-3-3" />
      </svg>
    </IconWrapper>
  );
};

export default NotificationsIcon;
