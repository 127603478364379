import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import Error from "../Error";
import { Modal, Button, Input } from "antd";
export const EditCreateItem = ({
  _visible,
  setVisible,
  title,
  onCreateClick,
  jsonKey,
  oldValue,
  validation,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
    if (oldValue) setInputValue(oldValue);
  }, [oldValue]);
  const handleOk = () => {
    const err = validation(
      jsonKey.toLowerCase().indexOf("cookie") > -1
        ? "Cookie name"
        : "Domain name",
      inputValue
    );
    if (err) {
      setError(err);
      return;
    }
    if (oldValue) onCreateClick(jsonKey, oldValue, inputValue);
    else onCreateClick(jsonKey, inputValue);
    setVisible(false);
    setError("");
  };

  const handleCancel = () => {
    setVisible(false);
    setError("");
  };

  return (
    <Modal
      title={title}
      visible={_visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      {error && <Error message={error} />}
    </Modal>
  );
};
