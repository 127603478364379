import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Modal, Input, Menu, List, Typography } from "antd";
import Button from "../Buttons/Button";
import DropDown from "../DropDown";
import { AlertPlatform } from "../../statics";
import { makeid } from "../../help";
import validator from "email-validator";
import Error from "../Error";
export const EditCreateUser = ({
  _visible,
  setVisible,
  title,
  user,
  setUsers,
}) => {
  const [userType, setUserType] = useState("");
  const [userDesc, setUserDesc] = useState("");
  const [userValue, setUserValue] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    setError("");
    setUserType(user ? user.type : "");
    setUserDesc(user ? user.name : "");
    setUserValue(user ? user.value : "");
  }, [user]);
  const handleCancel = () => {
    setError("");
    setVisible(false);
  };

  const menu = (
    <Menu
      onClick={(event) => {
        const platformKey = event.key;
        setUserType(platformKey.toString());
      }}
    >
      {Object.keys(AlertPlatform).map((e) => {
        return <Menu.Item key={e}>{AlertPlatform[e]}</Menu.Item>;
      })}
    </Menu>
  );

  return (
    <Modal
      title={title}
      visible={_visible}
      onOk={() => {
        if (!userType && !userDesc && !userValue) {
          setError("Type, Description and Value are required");
          return;
        } else if (!userType && !userDesc) {
          setError("Type and Description are required");
          return;
        } else if (!userType && !userValue) {
          setError("Type and Value are required");
          return;
        }
        if (!userDesc && !userValue) {
          setError("Description and Value are required");
          return;
        } else if (!userType) {
          setError("Type is required");
          return;
        } else if (!userDesc) {
          setError("Description is required");
          return;
        } else if (!userValue) {
          setError("Value is required");
          return;
        } else if (
          userType.toLowerCase() === "email" &&
          validator.validate(userValue) === false
        ) {
          setError("Email is not valid");
          return;
        }
        setError("");
        setUsers((prevState) => {
          let copy = [...prevState];
          if (!copy) copy = [];
          const i = copy.findIndex(
            (x) =>
              user &&
              x &&
              x.name === user.name &&
              x.type === user.type &&
              x.value === user.value
          );
          if (i === -1)
            copy.push({
              name: userDesc,
              type: userType,
              value: userValue,
              createdate: Date.now(),
            });
          else {
            copy[i]["name"] = userDesc;
            copy[i]["type"] = userType;
            copy[i]["value"] = userValue;
          }
          return copy;
        });
        setVisible(false);
      }}
      onCancel={handleCancel}
    >
      <DropDown dtitle={userType ? userType : "Add platform"} menu={menu} />
      <Input
        value={userDesc}
        placeholder="Insert Description"
        onChange={(e) => setUserDesc(e.target.value)}
      />
      <Input
        value={userValue}
        placeholder="Insert Value"
        onChange={(e) => setUserValue(e.target.value)}
      />
      {error && <Error message={error} />}
    </Modal>
  );
};
