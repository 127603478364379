import React, { useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter,
} from "react-router-dom";
import { AlowBlockSetting } from "./Route/AlowBlockSetting";
import { RegexList } from "./components/Regex/RegexList";
import Section from "./components/Section";
import { UserList } from "./components/Users/UserList";
import { getProtalData, saveProtalData } from "./db/dbUtil";
import { getToken } from "./token/tokenUtil";
import styled from "@emotion/styled";
import { PageHeading } from "./components/Typography";
import Button from "./components/Buttons/Button";
import { jsonFlatten } from "./help";
import { Tabs, Alert } from "antd";
import { InfoGuide } from "./Route/InfoGuide";
import { CustomRegexList } from "./components/Regex/CustomRegexList";
import { Alerts } from "./Route/Alerts";
import Sidebar from "./Route/Sidebar/Sidebar";
const { TabPane } = Tabs;
function App() {
  const [dataCompare, setDataCompare] = React.useState(
    undefined as {
      Allowed: [];
      Blocked: [];
      Regex: [{ rID: string; gID: string }];
      FCookies: [];
      Trusted: [];
    }
  );
  const [data, setData] = React.useState(
    undefined as {
      Allowed: [];
      Blocked: [];
      Regex: [{ rID: string; gID: string }];
      FCookies: [];
      Trusted: [];
      CustomRegex: [];
    }
  );
  useEffect(() => {
    getToken().then(() => {
      getProtalData().then((data) => {
        // console.log("setData");
        setData(data as any);
        setDataCompare(JSON.parse(JSON.stringify(data as any)));
      });
    });
  }, []);

  const Heading = styled(PageHeading)`
    margin-bottom: 40px;
  `;

  return (
    <HashRouter basename="/">
      <Sidebar />
      <div
        style={{
          display: "inline-grid",
          padding: "24px 22%",
        }}
      >
        <Heading>QP Lite</Heading>
        <Route
          path="/"
          render={({ location }) => (
            <>
              <Switch>
                {/* <Route path="/" component={UserList} exact /> */}
                <Route
                  path="/"
                  exact
                  component={() => (
                    <div>
                      {data ? (
                        ((data as any).error as any) ? (
                          (data as any).error
                        ) : (
                          <>
                            <AlowBlockSetting
                              data={data}
                              setData={setData}
                              keys={[
                                "Trusted",
                                "Allowed",
                                "Blocked",
                                "ACookies",
                                "BCookies",
                              ]}
                              titles={[
                                "Trusted Domains",
                                "Allowed Domains",
                                "Blocked Domains",
                                "Allowed Cookies",
                                "Blocked Cookies",
                              ]}
                            />
                            <Section title="Content Control">
                              <RegexList
                                regexList={data.Regex}
                                jsonKey={"Regex"}
                                setData={setData}
                              />
                            </Section>
                            <Section title="Custom Text Content Control">
                              <CustomRegexList
                                customRegex={data.CustomRegex}
                                setData={setData}
                                jsonKey={"CustomRegex"}
                              />
                            </Section>
                            {data &&
                              JSON.stringify(jsonFlatten(data)) !==
                                JSON.stringify(jsonFlatten(dataCompare)) && (
                                <Button
                                  onClick={async () => {
                                    await saveProtalData(data);
                                    setDataCompare(
                                      JSON.parse(JSON.stringify(data as any))
                                    );
                                  }}
                                >
                                  Save
                                </Button>
                              )}
                          </>
                        )
                      ) : (
                        "Loading"
                      )}
                    </div>
                  )}
                />

                <Route path="/notifications" component={UserList} />
                <Route path="/alerts" component={Alerts} />
                <Route
                  path="/sdk"
                  render={({ location }) => <InfoGuide data={dataCompare} />}
                />
              </Switch>
            </>
          )}
        />
      </div>
    </HashRouter>
  );
}

export default App;
