import React from "react";
import styled from "@emotion/styled";
import qpLogo from "./qp-logo.png";

const SidebarLogoWrapper = styled.div`
  width: 100px;
  height: 100px;
  background-image: radial-gradient(
    circle at 50% 50%,
    var(--light-navy),
    var(--dark-indigo)
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 49px;
  height: 33px;
  margin-top: 5px;
`;

interface SidebarLogoProps {}

const SidebarLogo: React.FunctionComponent<SidebarLogoProps> = (props) => {
  return (
    <SidebarLogoWrapper>
      <Logo src={qpLogo} />
    </SidebarLogoWrapper>
  );
};

export default SidebarLogo;
