import React, { useState, useEffect } from "react";
import Table from "../Table";
import Button from "../Buttons/Button";
import { makeid, jsonFlatten } from "../../help";
import { List, Typography, Modal } from "antd";
import { EditCreateUser } from "./EditCreateUser";
import { getToken } from "../../token/tokenUtil";
import { getProtalUsers, saveUsers } from "../../db/dbUtil";
import Section from "../Section";
interface UserItemProps {}

export const UserList: React.FunctionComponent<UserItemProps> = ({}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [createItemVisible, setCreateItemVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [users, setUsers] = React.useState(undefined);
  const [removedUsers, setRemovedUsers] = React.useState([]);
  const [usersBeforeSave, setUsersBeforeSave] = React.useState(undefined);
  useEffect(() => {
    getToken().then(() => {
      getProtalUsers().then((data) => {
        setUsers(data as any);
        setUsersBeforeSave(JSON.parse(JSON.stringify(data as any)));
      });
    });
  }, []);
  return (
    <Section title="Notifications">
      <div>
        {users && users.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <th />
                <th />
                <th>Description</th>
                <th>Type</th>
                <th>Value</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map((userData) => (
                <tr key={makeid()}>
                  <td style={{ width: 100 }}>
                    <Button
                      secondary
                      small
                      onClick={(event) => {
                        Modal.confirm({
                          title:
                            "Are you sure that you want to remove user " +
                            userData.name +
                            "?",
                          okText: "Yes",
                          cancelText: "No",
                          onOk: () => {
                            setUsers((prevState) => {
                              // const copy = [ ...prevState ];
                              return prevState.filter(
                                (x) => x.name !== userData.name
                              );
                              // return copy;
                            });
                            setRemovedUsers((prevState) => {
                              return [...prevState, userData];
                            });
                          },
                        });
                      }}
                    >
                      X
                    </Button>
                  </td>
                  <td style={{ width: 100 }}>
                    <Button
                      secondary
                      small
                      onClick={() => {
                        setCreateItemVisible(true);
                        setIsEdit(true);
                        setSelectedUser(userData);
                      }}
                    >
                      Edit
                    </Button>
                  </td>
                  <td
                    style={{
                      maxWidth: "20em",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {userData.name}
                  </td>
                  <td>{userData.type}</td>
                  <td
                    style={{
                      maxWidth: "20em",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {userData.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No user have been added yet.</p>
        )}
        <Button
          onClick={() => {
            setCreateItemVisible(true);
            setIsEdit(false);
          }}
        >
          {"Add notification"}
        </Button>
        {isEdit === true && (
          <EditCreateUser
            _visible={createItemVisible}
            setVisible={setCreateItemVisible}
            title={"Edit notification - " + selectedUser.name}
            user={selectedUser}
            setUsers={setUsers}
          />
        )}
        {isEdit === false && (
          <EditCreateUser
            _visible={createItemVisible}
            setVisible={setCreateItemVisible}
            title={"Add Notification"}
            user={undefined}
            setUsers={setUsers}
          />
        )}
        {users &&
          JSON.stringify(jsonFlatten(users)) !==
            JSON.stringify(jsonFlatten(usersBeforeSave)) && (
            <Button
              onClick={async () => {
                await saveUsers(users, removedUsers);
                setUsersBeforeSave(JSON.parse(JSON.stringify(users as any)));
                setRemovedUsers([]);
              }}
            >
              Save
            </Button>
          )}
      </div>
    </Section>
  );
};
