import React from "react";
import { ItemList } from "../components/ItemsList/ItemList";
import Section from "../components/Section";
import { makeid } from "../help";
import isValidDomain from "is-valid-domain";
import { hardCodedCookie } from "../statics";
export const AlowBlockSetting = ({ data, setData, keys, titles }) => {
  const remove = (key: string, name: string) => {
    setData((prevState) => {
      const copy = { ...prevState };
      copy[key] = prevState[key].filter((x) => x != name);
      return copy;
    });
  };

  const add = (key: string, name: string) => {
    setData((prevState) => {
      let copy = { ...prevState };
      if (!copy) copy = {};
      if (!copy[key]) copy[key] = [];
      const i = copy[key].findIndex((x) => x === name);
      if (i === -1) copy[key].push(name);
      return copy;
    });
  };
  const edit = (key: string, oldName: string, newName: string) => {
    setData((prevState) => {
      const copy = { ...prevState };
      const i = copy[key].findIndex((x) => x === oldName);
      copy[key][i] = newName;
      return copy;
    });
  };
  const validation = (key: string, value: string): string => {
    if (!value) return key + " is required";
    if (key.toLowerCase().includes("domain")) {
      if (isValidDomain(value) === false) return "Domain name is not valid";
    } else if (
      key.toLowerCase().includes("cookie") &&
      hardCodedCookie.map((c) => c.toLowerCase()).indexOf(value.toLowerCase()) >
        -1
    )
      return "Cookie name is reserved word";
  };
  return (
    <div className="App" style={{ display: "inline-grid" }}>
      {keys.map((j, index) => (
        <Section title={titles[index]} key={makeid()}>
          <ItemList
            key={j}
            title={titles[index]}
            itemList={data[j]}
            jsonKey={j}
            remove={remove}
            add={add}
            edit={edit}
            validation={validation}
            forwardToKey={
              j === "Allowed"
                ? "Blocked"
                : j === "Blocked"
                ? "Allowed"
                : j === "ACookies"
                ? "BCookies"
                : j === "BCookies"
                ? "ACookies"
                : undefined
            }
          />
        </Section>
      ))}
    </div>
  );
};
