import styled from "@emotion/styled";

export const PageHeading = styled.h1<{ light?: boolean }>`
  color: ${(props) =>
    props.light ? "var(--light-periwinkle)" : "var(--light-navy)"};
  font-family: Share Tech Mono, sans-serif;
  font-size: 44px;
  letter-spacing: 10px;
  line-height: 50px;
`;

export const SectionHeading = styled.h4<{ light?: boolean }>`
  font-size: 20px;
  letter-spacing: 0.5px;
  color: ${(props) =>
    props.light ? "var(--bluey-grey)" : "var(--light-navy)"};
`;
