import fetch from "node-fetch";
import { serverDomain, Header_Token } from "../statics";
import { getToken, removeToken, loginRedirect } from "../token/tokenUtil";

const fetchFailed = (retStatus: string, retText: string): JSON => {
  console.log("fetch failed");
  removeToken();
  const err = JSON.parse(`{"error":""}`);
  err.error = retStatus + " " + retText;
  if ((err.error = "403 access denied!")) loginRedirect();
  return err;
};

const setHeaders = async () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  headers[Header_Token] = JSON.stringify(await getToken());
  return headers;
};

export const getProtalUsers = async (): Promise<JSON> => {
  try {
    const response = await fetch(serverDomain + "/get/users", {
      method: "Get",
      headers: await setHeaders(),
    });
    const retStatus = await response.status;
    if (retStatus === 200) return await response.json();
    else {
      return fetchFailed(retStatus, await response.text());
    }
  } catch (err) {
    console.error("Error in getProtalData", err);
    throw err;
  }
};
export const getProtalData = async (): Promise<JSON> => {
  try {
    const response = await fetch(serverDomain + "/get/protal", {
      method: "Get",
      headers: await setHeaders(),
    });
    const retStatus = await response.status;
    if (retStatus === 200) return await response.json();
    else {
      return fetchFailed(retStatus, await response.text());
    }
  } catch (err) {
    console.error("Error in getProtalData", err);
    throw err;
  }
};

export const saveProtalData = async (data) => {
  try {
    // console.log("start save..", typeof data, data);
    const response = await fetch(serverDomain + "/set/protal", {
      method: "POST",
      headers: await setHeaders(),
      body: JSON.stringify(data),
    });
    const retStatus = await response.status;
    if (retStatus === 200) return await response.text();
    else {
      // return fetchFailed(retStatus, await response.text());
    }
  } catch (err) {
    console.error("Error in setProtalData", err);
    throw err;
  }
};

export const saveUsers = async (users, removedUsers) => {
  try {
    // console.log("removedUsers", removedUsers);
    // console.log("users", users);
    const response = await fetch(serverDomain + "/set/users", {
      method: "POST",
      headers: await setHeaders(),
      body: JSON.stringify({ users, removedUsers }),
    });
    const retStatus = await response.status;
    if (retStatus === 200) return await response.text();
    else {
      // return fetchFailed(retStatus, await response.text());
    }
  } catch (err) {
    console.error("Error in setProtalData", err);
    throw err;
  }
};
export const getAlerts = async (
  alertStatus: string,
  from: number = undefined,
  to: number = undefined
): Promise<JSON> => {
  try {
    console.log(alertStatus, from, to);
    let params = "";
    if (alertStatus) params += "alertStatus=" + alertStatus;
    if (from) {
      if (params) params += "&";
      params += "fromDate=" + from;
    }
    if (to) {
      if (params) params += "&";
      params += "toDate=" + to;
    }
    const response = await fetch(
      serverDomain + "/get/alerts" + (params ? "?" + params : ""),
      {
        method: "Get",
        headers: await setHeaders(),
      }
    );
    const retStatus = await response.status;
    if (retStatus === 200) return await response.json();
    else {
      return fetchFailed(retStatus, await response.text());
    }
  } catch (err) {
    console.error("Error in getProtalData", err);
    throw err;
  }
};
export const saveRemoeAlerts = async (removedAlerts) => {
  try {
    // console.log("removedAlerts", removedAlerts);
    const response = await fetch(serverDomain + "/remove/alerts", {
      method: "POST",
      headers: await setHeaders(),
      body: JSON.stringify({ removedAlerts: removedAlerts }),
    });
    const retStatus = await response.status;
    if (retStatus === 200) return await response.text();
    else {
      // return fetchFailed(retStatus, await response.text());
    }
  } catch (err) {
    console.error("Error in setProtalData", err);
    throw err;
  }
};
