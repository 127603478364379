import {
  serverDomain,
  Header_Code,
  Header_Token,
  Cognito_Domain,
  Pool_Region,
  Client_Id,
} from "../statics";

export const setToken = (token: string) => {
  //   const token = await headers.get(Header_Token);
  console.log("set token");
  localStorage.setItem("accounts:" + Header_Token, token);
};
export const removeToken = () => {
  console.log("removeToken");
  localStorage.removeItem("accounts:" + Header_Token);
};
export const refresh_token = async () => {
  const token = await getToken();
  if (!token || !token.refresh_token) return;
  const url = `https://${Cognito_Domain}.auth.${Pool_Region}.amazoncognito.com/oauth2/token`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: `grant_type=refresh_token&client_id=${Client_Id}&refresh_token=${token.refresh_token}`,
  });
  const status = await response.status;
  const bodyRes = await response.text();
  const body = JSON.parse(bodyRes);
  if (status !== 200 || body.error) {
    console.error("failed refresh token - ", body.error);
    removeToken();
    return;
  } else {
  }

  token["access_token"] = body.access_token;
  token["id_token"] = body.id_token;
  setToken(JSON.stringify(token));
};

export const getToken = async () => {
  const _getToken = () => {
    // console.log("get token");
    const ret = localStorage.getItem("accounts:" + Header_Token);
    if (ret) return JSON.parse(ret);
    console.log("token return..");
    return {};
  };
  const savedToken = _getToken();
  if (
    savedToken &&
    savedToken !== "null" &&
    savedToken !== "undefined" &&
    savedToken !== "" &&
    savedToken !== "{}" &&
    savedToken.access_token !== undefined
  )
    return savedToken;

  console.log("token not found - check code");
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (code) {
    const url = `https://${Cognito_Domain}.auth.${Pool_Region}.amazoncognito.com/oauth2/token`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `grant_type=authorization_code&client_id=${Client_Id}&code=${code}&redirect_uri=${document.location.origin}`,
    });

    const bodyRes = await response.text();
    const bodyJson = JSON.parse(bodyRes);
    if (bodyJson.error) {
      const errMsg = "ERROR in validateCode- " + bodyJson.error;
      console.error(errMsg);
      // throw new Error(errMsg);
      loginRedirect();
    }
    setToken(JSON.stringify(JSON.parse(bodyRes)));
    return _getToken();
  }
};

export const loginRedirect = () => {
  document.location.href = `https://${Cognito_Domain}.auth.${Pool_Region}.amazoncognito.com/oauth2/authorize?response_type=code&client_id=${Client_Id}&redirect_uri=${document.location.origin}`;
};
export const logoutRedirect = () => {
  document.location.href = `https://${Cognito_Domain}.auth.${Pool_Region}.amazoncognito.com/logout?client_id=${Client_Id}&response_type=code&redirect_uri=${document.location.origin}`;
};
setInterval(() => refresh_token(), 20 * 60 * 1000);
